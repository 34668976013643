import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";

import Breadcrumbs from "../../Components/Breadcrumbs";
import Label from "../../Components/Forms/Label";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import { handleUpdateProfile, usageInfo } from "../../services/user";
import { fnAddUserToDrip } from "../../utils/global";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const schema = object({
  first_name: string()
    .required("First name is required")
    .min(3, "Minimum 3 letters are required"),
  last_name: string()
    .required("Last name is required")
    .min(3, "Minimum 3 letters are required"),
  // company: string().required('Company name is required').min(3, 'Minimum 3 letters are required'),
  designation: string()
    .required("Job title is required")
    .min(3, "Minimum 3 letters are required"),
  email: string().email().required("Email is required"),
}).required();

const Profile = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuthProviderContext();
  //const { user } = auth;

  const [submitBtnloading, setSubmitBtnloading] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    text: "",
    cls: "text-primaryColor4",
  });
  const [showUpgrade, setShowUpgrade] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

// TODO temp removed for testing purpose, need to bee readded
  useEffect(() => {
    console.log(auth.user);
    if (auth.user) {
      try {
        (async () => {
          const res = await usageInfo();
          console.log(res);
          if (res.status == 200) {
            if (res.data.expired && showUpgrade) {
              fnAddUserToDrip();
              confirmAlert({
                title: "",
                message:
                  "You have exhausted your credits. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            } else if (res.data.expiringSoon && showUpgrade) {
              confirmAlert({
                title: "",
                message:
                  "Your credits are going to be finished. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            }
            setShowUpgrade(false);
          }
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);


  const updateProfile = async (data) => {
    setSubmitBtnloading(true);
    data = { ...data, type: "profile_update" };
    try {
      const res = await handleUpdateProfile(data);
      console.log(res);
      if (res.data.success) {
        setAuth((prev) => ({ ...prev, user: { ...auth.user, data } }));
        setSubmitBtnloading(false);
        setApiResponse({
          text: "Profile updated successfully",
          cls: "text-primaryColor1",
        });
      } else {
        setSubmitBtnloading(false);
        setApiResponse({
          text: "Something went wrong, please try again later!",
          cls: "text-primaryColor4",
        });
      }
      window.location.reload(true);
    } catch (err) {
      console.error(err);
    }
  };

  console.log("plan: " + auth.user.current_plan);

  let lblUsageInfo = "";

  let currentPlan = "";
  if (
    auth.user.current_plan != "" ||
    auth.user.current_plan != undefined ||
    auth.user.current_plan != "Starter"
  ) {
    currentPlan = auth.user.current_plan.split("-")[0];
  }

  if (currentPlan == "Pro") {
    lblUsageInfo = "5000";
  } else if (currentPlan == "Growth") {
    lblUsageInfo = "∞";
  } else {
    lblUsageInfo = "300";
  }

  return (
    <React.Fragment>
      <div className="bg-white">
        <Breadcrumbs pageTitle="Profile" title="Your Profile" />
      </div>
      <div className="bg-greyColor3 py-6 px-6 md:py-11 md:px-14">
        <form
          className="space-y-5 bg-white rounded-lg max-w-[800px] px-6 py-6 mb-6 md:space-y-7 md:px-10 md:py-14 md:mb-10"
          onSubmit={handleSubmit((data) => {
            updateProfile(data);
          })}
        >
          <FormField
            errors={errors}
            register={register}
            id="first_name"
            text="First Name"
            placeholder="First Name"
            value={auth.user.first_name}
          />
          <FormField
            errors={errors}
            register={register}
            id="last_name"
            text="Last Name"
            placeholder="Last Name"
            value={auth.user.last_name}
          />
          {/*<FormField errors={errors} register={register} id='company'*/}
          {/*           text='Which company are you from?' value={user.company}/>*/}
          <FormField
            errors={errors}
            register={register}
            id="designation"
            text="What is your job title?"
            value={auth.user.designation}
          />
          <FormField
            errors={errors}
            register={register}
            type="email"
            id="email"
            disabled="disabled"
            text="Email address"
            value={auth.user.email}
            ariaDescribedby={{
              id: "emailHelp",
              text: "We'll never share your email with anyone else.",
            }}
          />
          <div className="mb-6">
            <Label
              text="Words generated from AI"
              wordCount={{ min: auth.usageInfo, max: lblUsageInfo }}
            />
          </div>
          <div className="flex flex-col items-end">
            <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
            <Button
              text="Update Profile"
              width="w-auto"
              submitBtnloading={{
                loading: submitBtnloading,
                text: "Updating Profile",
              }}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default Profile;
