import axios from "axios";
// TODO - change baseURL to dev server
// const baseURL = "//localhost:8000/api";
// const baseURL = "//outreachai-backend-v2.vercel.app/api";
const baseURL = "//server.outreachai.co/api";

const AxiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
         'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        },
});

export const axiosInterceptor = async token => AxiosInstance.interceptors.request.use(
    async (config) => {
        config.headers = {
            ...config.headers,
            authorization: `Bearer ${token}`,
        };
        return config;
    },
    (error) => Promise.reject(error)
);

export default AxiosInstance
