import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Breadcrumbs from "../../Components/Breadcrumbs";
import "react-accessible-accordion/dist/fancy-example.css";
import FaqsData from "../../Data/FaqsData";
import { usageInfo } from "../../services/user";
import { fnAddUserToDrip } from "../../utils/global";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Faqs = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuthProviderContext();
  const [showUpgrade, setShowUpgrade] = useState(true);

  useEffect(() => {
    console.log(auth.user);
    if (auth.user) {
      try {
        (async () => {
          const res = await usageInfo();
          console.log(res);
          if (res.status == 200) {
            if (res.data.expired && showUpgrade) {
              fnAddUserToDrip();
              confirmAlert({
                title: "",
                message:
                  "You have exhausted your credits. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            } else if (res.data.expiringSoon && showUpgrade) {
              confirmAlert({
                title: "",
                message:
                  "Your credits are going to be finished. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            }
            setShowUpgrade(false);
          }
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-white">
        <Breadcrumbs pageTitle="FAQs" title="Frequently Asked Questions" />
      </div>
      <div className="bg-greyColor3 py-6 px-6 md:py-11 md:px-14">
        <div className="bg-white p-6 rounded-lg max-w-[800px] md:p-10">
          <Accordion allowZeroExpanded={true}>
            {FaqsData.map((accordion, index) => (
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>{accordion.head}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{accordion.text}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faqs;
