import React from "react";
import {createContext, useContext, useState, useEffect} from "react";
import {getProfile} from "../services/auth"
import axios from "axios";
import {axiosInterceptor} from "../AxiosInstance";
 

const AuthContext = createContext(null);
const initialState = {
    isLoggedIn: false,
    user: null,
    token: null,
    isLoading: true,
    usageInfo: 0
};

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState(initialState);

    const logout = () => {
        try {
         
          removeCookie('authStorageToken', {domain: '.outreachai.co'});
          removeCookie('extensionToken', {domain: '.outreachai.co'} );
          removeCookie('session', {domain: '.outreachai.co'});
          removeCookie('linkedinAuthorized', {domain: '.outreachai.co'});
          setAuth({...initialState, isLoading:false});

        //   window.location.href = '/login';
        } catch (err) {
          console.error(err)
        }
      };
      
    useEffect(() => {
       
        const authStorageToken = getCookie('authStorageToken');
        if (authStorageToken) {
            setAuth(prev => ({...prev, token: authStorageToken}))
        }
        else setAuth(prev=>({...prev,isLoading:false}));
       
    
    }, []);

    useEffect(() => {
        if (!auth.user && auth.token) {
            (async () => {
                console.log("getProfile called");
                try {
                    await axiosInterceptor(auth.token);
                    const res = await getProfile();
                    setAuth(prev => ({...prev, user: res.data.userInfo, isLoading: false, usageInfo: res.data.usageInfo}))
                } catch (err) {
                    console.error(err);
                    if(window.location.pathname !== '/login')
                      // logout();
                      window.location.href = '/login';
                      
                      return;
                    // window.location.href = '/login';
                }

                try {
                  const response = await axios({
                    method: 'GET',
                    url: 'https://automation.outreachai.co/api/user/check',
                    headers: {
                      'Authorization': `Bearer ${auth.token}`,
                      'Accept': 'application/json, text/plain, */*',
                    }
                  });
                  console.log(response);
          
                  if (response?.data?.success) {
                    // TODO temp fix
                    // if(window.location.pathname !== '/')
                    //  window.location.href = '/';
                    
                    }  
                } catch (error) {
                  if(window.location.pathname !== '/linkedin')
                    window.location.href = '/linkedin';
                }

            })();
        }
    }, [auth.token]);

    return <AuthContext.Provider value={{auth, setAuth, logout}}>{children}</AuthContext.Provider>;
};

export const useAuthProviderContext = () => useContext(AuthContext);

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

    options = {
      path: '/',
      // при необходимости добавьте другие значения по умолчанию
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
}

function removeCookie(name, options = {}) {
    options = {
      path: '/',
      ...options
    };
  
    let cookieString = encodeURIComponent(name) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  
    for (let optionKey in options) {
      cookieString += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        cookieString += "=" + optionValue;
      }
    }

    console.log("Attempting to remove cookie with string:", cookieString); // Debugging line
    document.cookie = cookieString;
}
